import React from 'react'
import { graphql } from "gatsby";
import { Container, Row, Col } from 'react-bootstrap'
import Layout from "../../components/Layout";
import SEO from "../../components/SEO";

const Services = ({ data }) => {

	const introduction = data.allMarkdownRemark.nodes;
	const software_development = data.software_development.nodes;
	const solution = data.solution.nodes;
	const sharepoint = data.sharepoint.nodes;
	const automate_process = data.automate_process.nodes;

	return (
		<Layout>
            <SEO title="Service Overview" description="Appvity provide app and collaboration tools that run on Microsoft Teams, our development service focuses on Office 365, Azure, SharePoint, and Power platform. We provide service that allow you to customize our eProducts for your enterprise's purpose." meta={[{ name: `keywords`, content: ["Appvity services", "app on Microsoft technologies", "O365 app", "app on SharePoint Online or Premises"] }]} pathname="/services" />
			<div className="main-content-padding">
				<div className="app-general-seciton app-section-service-introduction" data-sal="fade" data-sal-duration="1000" data-sal-delay="100" data-sal-easing="ease">
					<Container>
						<Row>
							<Col xs={12} md={12} lg={5}>
								{introduction.map((res) => (
                                    <div className="app-service-introduction-detail" dangerouslySetInnerHTML={{ __html: res.html }} key={res.id} />
                                ))}
							</Col>
							<Col xs={12} md={12} lg={7}></Col>
						</Row>
					</Container>
				</div>
				<div className="app-general-seciton app-section-our-services" data-sal="fade" data-sal-duration="1000" data-sal-delay="100" data-sal-easing="ease">
					<Container>
						<Row>
							<Col xs={12} md={12} lg={2}></Col>
							<Col xs={12} md={12} lg={8}>
								<div className="app-title">
									<h2>Our Professional Services</h2>
								</div>
							</Col>
							<Col xs={12} md={12} lg={2}></Col>
						</Row>
						<div className="app-our-services-detail">
							<Row>
								<Col className="app-our-services-detail-col-custom" xs={12} md={12} lg={6}>
									{software_development.map((res) => (
                                    	<div className="app-our-services-detail-left" dangerouslySetInnerHTML={{ __html: res.html }} key={res.id} />
                                	))}
								</Col>
								<Col xs={12} md={12} lg={6}>
									{solution.map((res) => (
                                    	<div className="app-our-services-detail-right" dangerouslySetInnerHTML={{ __html: res.html }} key={res.id} />
                                	))}
								</Col>
							</Row>
						</div>
					</Container>
				</div>
				<div className="app-servicelanding-spacing"></div>
				<div className="app-general-seciton app-section-sharepoint-automate-process" data-sal="fade" data-sal-duration="1000" data-sal-delay="100" data-sal-easing="ease">
                    <Container>
                        <div className="app-sharepoint-automate-process-detail">
							{sharepoint.map((res) => (
                            	<div className="app-sharepoint-automate-process-detail-left" dangerouslySetInnerHTML={{ __html: res.html }} key={res.id} />
                            ))}
							{automate_process.map((res) => (
                                <div className="app-sharepoint-automate-process-detail-right" dangerouslySetInnerHTML={{ __html: res.html }} key={res.id} />
                            ))}
                        </div>
                    </Container>
                </div>
				<div className="app-servicelanding-spacing-large"></div>
			</div>
		</Layout>
	)
}

export default Services;

export const query = graphql`
    query ServiceLanding {
        allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "service_landing_introduction" } } }
        ) {
            nodes {
                html
                id
            }
        }
		software_development: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "service_landing_software_development" } } }
        ) {
            nodes {
                html
                id
            }
        }
		solution: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "service_landing_solution" } } }
        ) {
            nodes {
                html
                id
            }
        }
		sharepoint: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "service_landing_sharepoint" } } }
        ) {
            nodes {
                html
                id
            }
        }
		automate_process: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "service_landing_automate_process" } } }
        ) {
            nodes {
                html
                id
            }
        }
    }
`;
